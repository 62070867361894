
import { Component, Emit, Model, Prop, Vue, Watch } from "vue-property-decorator";
import Rules from "@/app/modules/_global/classes/Rules";

@Component
export default class Input extends Vue {
  @Prop(Object) readonly field?: Record<string, unknown>;
  @Prop(String) readonly label?: string;
  @Prop(Boolean) readonly dense?: boolean;
  @Prop({ type: String, default: "solo" }) readonly design?: string;
  @Prop({ type: Boolean, default: false }) readonly readonly?: boolean;
  @Prop({ type: String, default: "text" }) readonly type?: string;

  @Model("input") readonly data!: number | string;

  @Emit()
  input() {
    return this.localData;
  }

  @Watch("localData")
  onLocalDataChanged() {
    this.input();
  }

  @Watch("data")
  onDataChanged(value: number | string) {
    this.localData = value;
  }

  localData: number | string;

  rules = {
    required: this.field?.required ? Rules.required : true
  };

  constructor() {
    super();

    this.localData = this.data;
  }
}
